import {
    Box,
    Card,
    CardContent,
    createStyles,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Check, CheckCircle} from "@material-ui/icons";
import ReactCardFlip from "react-card-flip";
import {RatingEnum, Scoring} from "./Scoring";
import {GameState} from "../objects/objects";

export interface AnswerCardProps {
    answerText: string | undefined,
    answererName: string,
    isMe: boolean,
    gameState: GameState,
    onFinishedAnswering?: (answerText: string) => void,
    questionId: string,
    numPlayers: number,
    questionAbout: string,
    myName: string,
    submitRating: (rating: RatingEnum, ratingAboutPerson: string) => void,
    rating?: RatingEnum,
}

interface InputAnswerCardProps {
    answererName: string,
    isMe: boolean,
    onFinishedAnswering?: (answerText: string) => void,
    questionId: string,
    cardStyle?: React.CSSProperties,
    questionAbout: string,
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            ...theme.typography.h4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "top",
            height: "100%",
            '& textarea': {
                flex: "1"
            }
        },
    })
);

const EndAdornment = withStyles({
    root: {
        alignSelf: "flex-end",
        marginBottom: "1.5rem",
        marginRight: "0rem",
        marginLeft: '0',
        padding: '0rem',
    }
})(InputAdornment)

export const InputAnswerCard = ({
                                    isMe,
                                    answererName,
                                    onFinishedAnswering,
                                    questionId,
                                    cardStyle,
                                    questionAbout
                                }: InputAnswerCardProps) => {
    const classes = useStyles();
    const [inputText, setInputText] = useState("")
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setInputText("");
        setSubmitted(false);
    }, [questionId, questionAbout]);

    const onSubmit = () => {
        onFinishedAnswering!(inputText);
        setSubmitted(true);
    }

    if (isMe) {
        return (
            <Card style={cardStyle}>
                <CardContent
                    style={{padding: '24px', height: "100%", display: "flex", flexDirection: "column", flex: 1}}>
                    <Box>
                        <Typography variant="overline">
                            {answererName} says...
                        </Typography>
                    </Box>
                    <Box style={{flex: "1", display:"flex", flexDirection: "column"}}>
                        <TextField value={inputText} onChange={event => {
                            setInputText(event.target.value);
                            setSubmitted(false);
                        }}
                                   multiline fullWidth style={{flex: 1}} InputProps={{
                            className: classes.root,
                            style: {flex: "1"}, // (for adornment)
                            endAdornment:
                                <EndAdornment position={"end"}>
                                    <IconButton onClick={onSubmit} name="Submit Answer">
                                        {
                                            submitted ?
                                                <CheckCircle fontSize="large" style={{color: "green"}}/>
                                                :
                                                <Check fontSize="large" color="primary"/>
                                        }
                                    </IconButton>
                                </EndAdornment>
                        }}/>
                    </Box>
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Card style={cardStyle}>
                <CardContent style={{padding: '24px'}}>
                    <Typography variant="overline">
                        {answererName} says...
                    </Typography>
                    <Typography variant="h4" color="secondary">
                        Typing...
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export const AnswerCard = ({
                               answerText,
                               answererName,
                               isMe,
                               gameState,
                               onFinishedAnswering,
                               questionId,
                               numPlayers,
                               questionAbout,
                               submitRating,
                               myName,
                               rating
                           }: AnswerCardProps) => {
    let height;
    if (numPlayers > 2) {
        height = "30vh"
    } else {
        height = "35vh"
    }

    const [isFlipped, setFlipped] = useState(gameState === "reviewing");

    // isFlipped is just "gameState==="reviewing"" but with a delay.
    // There is no delay when the gameState goes from reviewing to answering, but there is a delay
    // when it goes from answering to reviewing
    useEffect(() => {
        console.log(gameState)
        if (gameState === "answering") {
            setFlipped(false)
        } else {
            const timeout = setTimeout(() => setFlipped(gameState === "reviewing"), 400);
            return function cleanup() {
                clearTimeout(timeout)
            }
        }
    }, [gameState])

    return (
        <ReactCardFlip isFlipped={isFlipped}>
            <InputAnswerCard key="front" answererName={answererName} isMe={isMe}
                             onFinishedAnswering={onFinishedAnswering}
                             questionId={questionId}
                             cardStyle={{minHeight: height, display: "flex", flexDirection: "column"}}
                             questionAbout={questionAbout}
            />

            <Card key="back" style={{minHeight: height, display: "flex", flexDirection: "column"}}>
                <CardContent style={{padding: '24px', display: "flex", flexDirection: "column", flex: 1}}>
                    <div style={{flex: "1"}}>
                        <Typography variant="overline">
                            {answererName} says...
                        </Typography>
                        <Typography variant="h4" color={answererName === questionAbout ? "primary" : undefined}>
                            {answerText}
                        </Typography>
                    </div>
                    {questionAbout !== answererName &&
                    <Scoring scoringPerson={questionAbout}
                             scoredPerson={answererName}
                             submitRating={submitRating}
                             rating={rating}
                             myName={myName}
                    />}
                </CardContent>
            </Card>
        </ReactCardFlip>
    );
}