export type GameState = "answering" | "reviewing";

export interface QuestionTemplate {
    id: string,
    nsfw: boolean,
    difficulty: number,
    templateText: string
}

export interface Question {
    template: QuestionTemplate,
    questionAbout: string,
    promptText: string,
}

export interface GameContent {
    gameStateLabel: GameState,
    question: Question,
    answers?: object,
    readyForNext: boolean
}

export interface Answer {
    answerText: string,
    rating?: "yes" | "no" | "maybe"
}

export interface GameConfig {
    players: [string],
    configOptions: GameConfigOptions
}

export interface GameConfigOptions {
    generalOptions: GeneralOptions,
    questionPackOptions: QuestionPackOptions,
}

export interface GeneralOptions {
    nsfw: NsfwOption
}

export interface NsfwOption {
    name: "nsfw"
    value: boolean
    // cls : "boolean" todo
}

export interface QuestionPackOptions {
    questionPacks: Map<QuestionPack, boolean>
    name: "Question Packs"
}

export enum QuestionPack {
    General = "General",
    CompSci = "CompSci",
    Uni = "Uni",
    Math = "Math",
}

export const questionPackLabels = new Map<QuestionPack, string>([
    [QuestionPack.General, "Base Question Pack"],
    [QuestionPack.CompSci, "Computer Science Question Pack"],
    [QuestionPack.Uni, "University Pack"],
    [QuestionPack.Math, "Math Pack"]
])

export const questionPacksMapReviver: ((key: string, value: any) => any) = (key, value) => {
    if (key === "questionPacks" && typeof value == 'object' && value !== null) {
        return new Map(Object.entries(value));
    }
    return value;
}
export const questionPacksMapReplacer: ((key: string, value: any) => any) = (key, value) => {
    if (value instanceof Map) {
        return Object.fromEntries(value);
    }
    return value;
}

export interface RoomMsg {
    id: string,
    gameBuilder: GameConfig,
    gameIsInitialized: boolean,
}

/**
 * Msg for submitting a rating
 */
export interface RatingMsg {
    submittingPerson: string,
    ratingAboutPerson: string,
    rating: string
}