import {Box, Checkbox, FormControlLabel, FormGroup, Switch} from "@material-ui/core";
import {
    GameConfigOptions,
    questionPackLabels,
    questionPacksMapReplacer,
    questionPacksMapReviver
} from "./objects/objects";

interface GameOptionsConfigurerProps {
    gameOptions: GameConfigOptions,
    sendConfig: (gameConfig: GameConfigOptions) => void,
    disabled: boolean,
}

export function GameOptionsConfigurer({gameOptions, sendConfig, disabled}: GameOptionsConfigurerProps) {
    return (
        <Box mx={3} mb={2} mt={1}>
            {/*<Paper variant="outlined" style={{padding: "1rem 1.5rem"}}>*/}
            {/*<Typography variant="caption" >*/}
            {/*    Game Options*/}
            {/*</Typography>*/}
            <FormGroup row>
                <FormControlLabel
                    disabled={disabled}
                    control={
                        <Switch
                            name="checkedB"
                            color="primary"
                            size="small"
                            checked={gameOptions.generalOptions.nsfw.value}
                            onChange={(event) => {
                                let cloneOptions: GameConfigOptions = JSON.parse(JSON.stringify(gameOptions, questionPacksMapReplacer), questionPacksMapReviver)
                                cloneOptions.generalOptions.nsfw.value = event.target.checked
                                sendConfig(cloneOptions)
                            }
                            }
                        />
                    }
                    label="NSFW"
                />
                {
                    Array.from(gameOptions.questionPackOptions.questionPacks).map(
                        ([key, value]) => (
                            <FormControlLabel
                                disabled={disabled}
                                control={
                                    <Checkbox
                                        name="checkedC"
                                        size="small"
                                        checked={value}
                                        onChange={(event) => {
                                            let newState = event.target.checked
                                            let cloneOptions: GameConfigOptions = JSON.parse(JSON.stringify(gameOptions, questionPacksMapReplacer), questionPacksMapReviver)
                                            cloneOptions.questionPackOptions.questionPacks.set(key, newState)
                                            console.log(cloneOptions)
                                            sendConfig(cloneOptions)
                                        }}
                                    />
                                }
                                label={questionPackLabels.get(key)}
                                style={{textAlign: 'left'}}
                                key={key}
                            />
                        )
                    )
                }
            </FormGroup>
            {/*</Paper>*/}
        </Box>
    );
}