import Game from "./Game";
import {Container, createStyles, Link, makeStyles, Typography} from "@material-ui/core";
import {Header} from "./components/Header";
import {HashRouter, Link as RouterLink, Route, Switch, useRouteMatch} from 'react-router-dom';
import {StartPage} from "./StartPage";
import {GameConfigPage} from "./GameConfigPage";
import {useEffect, useRef} from "react";
import {useParams} from "react-router";
import {RxStomp, RxStompConfig} from "@stomp/rx-stomp";
import axios from "axios";
import Cookies from "js-cookie";
import {Legal} from "./pages/Legal";
import {About} from "./pages/About";
import {SWEETENER_WEBSOCKET_BACKEND_URL} from "./server-location";

export const LinkWrapper = ({navigate, ...props} : any) => (
    <Link {...props}/>
)

/**
 * To let footer at bottom of page. All parent containers must be full height (see index.tsx).
 * Then the direct parent of the footer has display: flex, and the body content has flex "1".
 */
const useFooterStyles = makeStyles(() =>
    createStyles({
        directContainer: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        body: {
            flex: 1
        },
    })
);


export function App() {
    const classes = useFooterStyles();
    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = Cookies.get("XSRF-TOKEN")
    })

    return (
        <Container maxWidth="lg" className={classes.directContainer}>
            <HashRouter>
                <div className={classes.body}>
                    <Header/>
                    <Switch>
                        <Route exact path="/">
                            <StartPage/>
                        </Route>

                        <Route path="/legal">
                            <Legal/>
                        </Route>

                        <Route path="/about">
                            <About/>
                        </Route>

                        <Route path="/:roomId">
                            <Room/>
                        </Route>

                    </Switch>
                </div>
                <div style={{paddingBottom: "1.2em", paddingTop: "1.2em", textAlign: "right", width: "100%", opacity: "80%"}}>
                    <Typography variant="caption" color="inherit">
                        <RouterLink to="/about" component={LinkWrapper} color="inherit">About</RouterLink>


                        <RouterLink to="/legal" style={{marginLeft: "3em", marginRight: "3em"}} component={LinkWrapper}
                                    color="inherit">Legal</RouterLink>
                    </Typography>
                </div>
            </HashRouter>
        </Container>
    )
}


function Room() {
    let {path} = useRouteMatch();
    let {roomId} = useParams<{ roomId: string }>();

    const stompClient = useRef(new RxStomp());

    useEffect(() => {
        var headers = {}
        // @ts-ignore
        headers['X-XSRF-TOKEN'] = Cookies.get("XSRF-TOKEN");

        const stompConfig: RxStompConfig = {
            brokerURL: SWEETENER_WEBSOCKET_BACKEND_URL,
            connectHeaders: headers,
            // Keep it off for production, it can be quit verbose
            // Skip this key to disable
            debug: function (str) {
                console.log('STOMP: ' + str);
            },


            // If disconnected, it will retry after 200ms
            reconnectDelay: 200,
        }

        stompClient.current.configure(stompConfig)
        stompClient.current.activate()

        stompClient.current.watch("/user/queue/error").subscribe((message) => {
            alert(message.body)
            console.error(message.body)
        })

    }, [roomId]);

    if (!stompClient.current) {
        return <h5>Loading</h5>
    }

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <GameConfigPage stompClient={stompClient.current!}/>
            </Route>
            <Route path={`${path}/:myName/game`}>
                <Game stompClient={stompClient.current!}/>
            </Route>
        </Switch>
    )
}