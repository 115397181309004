const SWEETENER_BACKEND_DOMAIN = process.env.NODE_ENV === "production" ?
    `api.sweetener.me`
    : "localhost:5001";

const SWEETENER_WEBSOCKET_BACKEND_URL = process.env.NODE_ENV === "production"?
    `wss://${SWEETENER_BACKEND_DOMAIN}/game-websocket/websocket`
    : `ws://${SWEETENER_BACKEND_DOMAIN}/game-websocket/websocket`;

const SWEETENER_REST_BACKEND_URL = process.env.NODE_ENV === "production" ?
    `https://${SWEETENER_BACKEND_DOMAIN}`
    : `http://${SWEETENER_BACKEND_DOMAIN}`;

export {
    SWEETENER_BACKEND_DOMAIN, SWEETENER_WEBSOCKET_BACKEND_URL, SWEETENER_REST_BACKEND_URL
}