import {Box, Button, CircularProgress, Paper, TextField, Tooltip} from "@material-ui/core";
import {useEffect, useState} from "react";
import {Redirect, useParams} from "react-router";
import {RxStomp} from "@stomp/rx-stomp";
import {FileCopyOutlined} from "@material-ui/icons";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    GameConfig,
    GameConfigOptions,
    questionPacksMapReplacer,
    questionPacksMapReviver,
    RoomMsg
} from "./objects/objects";
import {GameOptionsConfigurer} from "./GameOptionsConfigurer";

export function GameConfigPage({stompClient}: { stompClient: RxStomp }) {
    let {roomId} = useParams<{ roomId: string }>();

    const [gameConfig, setGameConfig] = useState<GameConfig>();
    const [gameStarted, setGameStarted] = useState(false);

    useEffect(() => {
        const gameConfigSubscription = stompClient.watch(`/topics/gameConfig/${roomId}`).subscribe(message => {
            const roomMsg: RoomMsg = JSON.parse(message.body, questionPacksMapReviver)
            setGameConfig(roomMsg.gameBuilder)
            setGameStarted(roomMsg.gameIsInitialized)
            console.log(roomMsg.gameBuilder)
        })

        stompClient.publish({
            destination: `/app/refreshAll/${roomId}`,
        })

        return function cleanup() {
            gameConfigSubscription.unsubscribe();
        }

    }, [roomId, stompClient])

    const [name, setName] = useState("");
    const [submittedName, setSubmittedName] = useState("");


    const startGame = () => {
        stompClient.publish({
            destination: `/app/startGame/${roomId}`
        })
    }

    const [linkCopied, setLinkCopied] = useState(false);

    if (gameStarted) {
        return (
            <Redirect to={`/${roomId}/${submittedName}/game`}/>
        );
    }

    if (!gameConfig) {
        return (<Box textAlign="center"><CircularProgress/></Box>);
    }

    const submitName = () => {
        setSubmittedName(name)
        if (!gameConfig.players.includes(name)) {
            // Only add player if he isn't there yet
            stompClient.publish({
                destination: `/app/addPerson/${roomId}`,
                body: `"${name}"`
            });
        }
    }

    const sendConfig = (gameConfig: GameConfigOptions) => {
        stompClient.publish({
            destination: `/app/configureGame/${roomId}`,
            body: JSON.stringify(gameConfig, questionPacksMapReplacer)
        })
    }

    const nameIsSubmitted = gameConfig.players.includes(submittedName);

    return (
        <Box textAlign="center">
            <Box m={3}>
                <Paper variant="outlined">
                <span style={{display: "inline-block", verticalAlign: "middle"}}>
                <p style={{display: "inline", verticalAlign: "middle"}}>
                    Link: {window.location.href}
                </p>
                    &nbsp;
                    <CopyToClipboard text={window.location.href} onCopy={() => setLinkCopied(true)}>
                <Button style={{fontSize: "0.75rem", margin: "3px"}}
                        startIcon={<FileCopyOutlined style={{fontSize: "1rem", marginRight: "-4px"}}/>}>
                    {linkCopied ? "Copied" : "Copy"}
                </Button>
                    </CopyToClipboard>
                </span>
                </Paper>
            </Box>
            <Box m={2}>
                <TextField placeholder="Your Name" value={name} onChange={(event => setName(event.target.value))}
                           disabled={gameConfig.players.includes(submittedName)}/>
                <Button onClick={submitName} disabled={nameIsSubmitted}>Submit</Button>
            </Box>
            <Box m={2}>
                {gameConfig.players.join("\n")}
            </Box>

            <Box m={3}>
                <Paper variant="outlined" style={{padding: "1rem 1.5rem"}}>

                    <GameOptionsConfigurer gameOptions={gameConfig.configOptions} sendConfig={sendConfig}
                                           disabled={false}/>

                    {
                        nameIsSubmitted ?
                            <Button onClick={startGame}>Start Game</Button>
                            :
                            <Tooltip title="You must submit a name first!" arrow enterTouchDelay={1}>
                                <div>
                                    <Button onClick={startGame} disabled>Start Game</Button>
                                </div>
                            </Tooltip>
                    }

                </Paper>
            </Box>
        </Box>
    );
}

