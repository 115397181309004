import {Box, Typography} from "@material-ui/core";
import React from "react";

export const Prompt = ({text}: { text: string }) => {
    return (
        <Box my={"8vh"}>
            <Typography variant="h3" align="center">
                {text}
            </Typography>
        </Box>
    );
}