import {Box, Button} from "@material-ui/core";
import axios from "axios";
import {useState} from "react";
import {Redirect} from "react-router";
import {RoomMsg} from "./objects/objects";
import {SWEETENER_REST_BACKEND_URL} from "./server-location";

export function StartPage() {
    const [redirect, setRedirect] = useState("")

    if (redirect) {
        return (
            <Redirect to={redirect}/>
        );
    }

    const newGame = () => {
        console.log("New game")
        axios.post<RoomMsg>(`${SWEETENER_REST_BACKEND_URL}/room`)
            .then((response) => {
                const id = response.data.id
                setRedirect(`/${id}`)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
      <Box textAlign="center">
          <Button onClick={newGame}>New Game</Button>
      </Box>
    );
}