import {Box, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom"

export const Header = () => {
    return (
        <Box my={4}>
            <Link to="/" style={{ textDecoration: 'none', color: 'black'}}>
                <Typography variant="h4" component="h1" align="center" gutterBottom style={{fontFamily: "monospace"}}>
                    sweetener.
                </Typography>
            </Link>
        </Box>
    );
}