import {Box, Typography} from "@material-ui/core";
import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";


export interface ScoringProps {
    submitRating: (rating: RatingEnum, ratingAboutPerson: string) => void
    scoredPerson: string
    scoringPerson: string
    rating?: RatingEnum
    myName: string
}

export type RatingEnum = "yes" | "no" | "maybe"

export const Scoring = ({ submitRating, scoredPerson, scoringPerson, rating, myName } : ScoringProps) => {

    const handleChange : (event: React.MouseEvent<HTMLElement>, value: any) => void = (_, newRating) => {
        if (!newRating) {
            return;
        }

        submitRating(newRating, scoredPerson)
    };

    if (myName === scoringPerson) {
        return (
            <Box textAlign="center" mt="2rem" mb="1rem">
                <Box m="0.5rem">
                    <Typography variant="body2">
                        Rate {scoredPerson}'s answer!
                    </Typography>
                </Box>
                <Box mt="1rem">
                    <ToggleButtonGroup size="small"
                        value={rating}
                        exclusive
                        onChange={handleChange}
                        >
                        <ToggleButton value="yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value="no">
                           No
                        </ToggleButton>
                        <ToggleButton value="maybe">
                           Maybe?
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
        )
    }

    return (
        <Box textAlign="center" mt="2rem" mb="1rem">
            {rating ?
                <Box mt="1rem">
                    <Typography variant="body2">
                        {scoringPerson} has given {scoredPerson} the rating: {rating.toUpperCase()}!
                    </Typography>
                </Box>
                :
                <Box mt="1rem">
                    <Typography variant="body2">
                        {scoringPerson} is rating {scoredPerson}'s answer!
                    </Typography>
                </Box>
            }
        </Box>
    )
}
