import {Box, Button} from "@material-ui/core";
import React from "react";

export interface ContinueButtonProps {
    isReadyForNext: boolean,
    toNextState: () => void
}

export function ContinueButton({ isReadyForNext, toNextState }: ContinueButtonProps) {
    return (
        <Box textAlign="center" mt="1.5rem" mb="1rem">
            {isReadyForNext && <Button onClick={toNextState}>Continue</Button>}
        </Box>
    )
}