import React, {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import {Prompt} from "./components/Prompt";
import {AnswerCard} from "./components/AnswerCard";
import {RatingEnum} from "./components/Scoring";
import {useParams} from "react-router";
import {RxStomp} from "@stomp/rx-stomp";
import {ContinueButton} from "./components/ContinueButton";
import {Answer, GameConfig, GameContent, RatingMsg, RoomMsg} from "./objects/objects";


function Game({stompClient}: { stompClient: RxStomp }) {

    let {roomId, myName} = useParams<{ roomId: string, myName: string }>();

    const [gameConfig, setGameConfig] = useState<GameConfig>();
    const [gameContent, setGameContent] = useState<GameContent>();

    useEffect(() => {
        stompClient.watch(`/topics/gameState/${roomId}`)
            .subscribe((message) => {
                const gameContent: GameContent = JSON.parse(message.body)
                setGameContent(gameContent)
                console.log(message)
            })

        stompClient.watch(`/topics/gameConfig/${roomId}`)
            .subscribe((message) => {
                const roomMsg: RoomMsg = JSON.parse(message.body)
                setGameConfig(roomMsg.gameBuilder)
                console.log(message)
            })

        stompClient.publish({
            destination: `/app/refreshAll/${roomId}`
        });
    }, [roomId, stompClient]);

    if (gameContent === undefined || gameConfig === undefined) {
        return (
            <h5>Loading...</h5>
        )
    }

    const getAnswerOfPlayer = (player: string) : Answer | undefined => {
        //@ts-ignore
        return gameContent.answers?.[player];
    }

    const submitAnswer = (answerText: string) => {
        stompClient.publish({
            destination: "/app/submitAnswer/" + roomId,
            body: JSON.stringify({
                player: myName,
                answer: answerText
            })
        });
    }

    const submitRating = (rating: RatingEnum, ratingAboutPerson: string) => {
        const msg : RatingMsg = {
            submittingPerson: myName,
            ratingAboutPerson: ratingAboutPerson,
            rating: rating
        }
        stompClient.publish({
            destination: "/app/submitRating/" + roomId,
            body: JSON.stringify(msg)
        })
    }

    const nextState = () => {
        stompClient.publish({
            destination: "/app/nextState/" + roomId
        })
    }

    return (
        <div>
            <Prompt text={gameContent.question.promptText}/>

            <Grid container spacing={3} wrap="wrap">
                {
                    gameConfig.players
                        .filter(player => player !== myName)
                        .map((player) =>
                            <Grid item xs style={{minWidth: "25em"}} key={player}>
                                <AnswerCard answerText={getAnswerOfPlayer(player)?.answerText} answererName={player} isMe={false}
                                            gameState={gameContent.gameStateLabel}
                                            questionId={gameContent.question.template.id} numPlayers={gameConfig.players.length}
                                myName={myName} rating={getAnswerOfPlayer(player)?.rating} submitRating={submitRating} questionAbout={gameContent?.question.questionAbout}
                                />
                            </Grid>)
                }
                <Grid item xs style={{minWidth: "25em"}}>
                    <AnswerCard answerText={getAnswerOfPlayer(myName)?.answerText} answererName={myName} isMe={true}
                                gameState={gameContent.gameStateLabel} onFinishedAnswering={submitAnswer}
                                questionId={gameContent.question.template.id} numPlayers={gameConfig.players.length}
                                myName={myName} rating={getAnswerOfPlayer(myName)?.rating} submitRating={submitRating} questionAbout={gameContent?.question.questionAbout}
                    />
                </Grid>
            </Grid>
            <ContinueButton isReadyForNext={gameContent.readyForNext} toNextState={nextState}/>
        </div>
    );
}

export default Game;
