import {Box, Typography} from "@material-ui/core";

export function About() {
    return (
        <Box>
            <Typography variant="h4">
                About Sweetener
            </Typography>
            <Typography variant="body1" style={{marginTop: "5em"}}>
                Welcome to Sweetener: a fun personality game for friends, strangers, and first dates.

                Sweetener is a real-time multiplayer game based on the 'Newlyweds Game'. Answer interesting questions and see how well you know your fellow players! You can play with 2 or more players, and can also choose from a variety of question packs to make your quiz more interesting.
                
                To begin, create a new game and share the link with your friend(s).

                This game is still in an early stage of development, so please be
                patient with me :)
            </Typography>
        </Box>
    )
}