import {Box, Typography} from "@material-ui/core";

export function Legal() {
    return (
        <Box>
            {/*<Typography variant="h4">*/}
            {/*    Impressum*/}
            {/*</Typography>*/}
            <Typography variant="body1" style={{marginTop: "5em"}}>
                Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
            </Typography>
        </Box>
    )
}